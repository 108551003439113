// This data structure is copied verbatim from
// crates/common/src/models/rbac/definitions.rs and will be transformed below
// into a TS-friendly format before being exported.
const permissionsFromRust = {
  ApiKeys: {
    READ: "View API keys (but not secrets)",
    CREATE: "Create new API keys",
    DELETE: "Delete or expire existing API keys",
  },

  Blobs: {
    CREATE: "Create new file blobs",
    READ: "Read or download a blob record",
    DELETE: "Delete a blob record",
  },

  Machines: {
    CREATE: "Create new machine records",
    READ: "View machine information",
    SUPPORT: "View machine support information",
    WRITE: "Edit machine properties",
    DELETE: "Delete machine records",
    WRITE_PROTECTED: "Edit protected machine and hardware properties",
  },

  Hardware: {
    TRANSFER: "Transfer hardware between machine records",
  },

  Manufacturers: {
    CREATE: "Create a new manufacturer record",
    READ: "Create a new manufacturer record",
    WRITE: "Update a manufacturer record",
  },

  Materials: {
    READ: "View material information",
    CREATE: "Create a new material record",
    WRITE: "Update a material",
    DELETE: "Delete a material",
  },

  Models: {
    DELETE: "Delete models",
    READ: "Read and view models",
    WRITE: "Create and update models",
  },

  Organizations: {
    CREATE: "Create a new organization",
    DELETE: "Delete an organization",
    READ: "View organization information",
    REVIEW_MEMBER: "Review user membership for an org",
    WRITE: "Update an organization",
  },

  Projects: {
    READ: "View details of a project",
    WRITE: "Create or update projects",
    DELETE: "Delete a project",
  },

  Roles: {
    READ: "View available roles and assignments",
    WRITE: "Assign roles to actors",
    DELETE: "Remove role assignments",
  },

  Telemetry: {
    WRITE: "Write machine telemetry data",
  },

  Users: {
    READ: "Read user information",
    WRITE: "Read user information",
    DELETE: "Delete a user",
    INVITE: "Invite a new user",
  },

  Views: {
    MANAGE: "View org and user management pages and views.",
    SUPERADMIN: "View superadmin and system management pages and views.",
  },
};

type RawPermissions = typeof permissionsFromRust;

type CamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${Lowercase<T>}${Capitalize<CamelCase<U>>}`
  : Lowercase<S>;

type TransformPermissions<T> = {
  [K in keyof T as Uncapitalize<string & K>]: {
    [K2 in keyof T[K] as CamelCase<
      K2 & string
    >]: `${Uncapitalize<string & K>}/${CamelCase<K2 & string>}`;
  };
};

/**
 * Type of the exported `permissions` object that makes it easy and type-safe to
 * choose the correct permissions string.
 *
 * Example usage:
 * * `permissions.machines.read === "machines/read"`
 * * `permissions.users.delete === "users/delete"`
 */
export type Permissions = TransformPermissions<RawPermissions>;

/**
 * Top-level groupings of permissions, each focused on a particular thing.
 *
 * Examples: "machines", "users", "projects", etc.
 */
export type PermissionResource = keyof Permissions;

type InnerKeys<T> =
  T extends Record<string, infer U>
    ? U extends Record<string, unknown>
      ? keyof U
      : never
    : never;
/**
 * The universe of possible actions that can be taken using a permission.
 *
 * Examples: "read", "write", "create", "delete", etc.
 */
export type PermissionAction = InnerKeys<Permissions>;

type LeafValues<T> = T extends Record<string, infer U> ? LeafValues<U> : T;

/**
 * Type of each permission string, e.g. "machines/read", "machines/write", ...
 */
export type Permission = LeafValues<Permissions>;

const screamingSnakeToCamelCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

const mapObject = <
  SrcKey extends string,
  DestKey extends string,
  SrcVal,
  DestVal,
>(
  obj: Record<SrcKey, SrcVal>,
  fn: (v: [SrcKey, SrcVal]) => [DestKey, DestVal]
): Record<DestKey, DestVal> =>
  Object.fromEntries(Object.entries(obj).map((kv) => fn(kv))) as Record<
    DestKey,
    DestVal
  >;

const transformPermissions = (): Permissions => {
  const transformResource = (
    resource: PermissionResource,
    obj: Record<string, string>
  ): Record<PermissionAction, Permission> => {
    return mapObject(obj, ([k, _]) => {
      const action = screamingSnakeToCamelCase(k) as PermissionAction;
      return [action, `${resource}/${action}` as Permission];
    });
  };

  return mapObject(permissionsFromRust, ([k, v]) => {
    const resource = (k[0].toLowerCase() + k.slice(1)) as PermissionResource;
    return [resource, transformResource(resource, v)];
  }) as Permissions;
};

export const permissions = transformPermissions();
