import React, { ReactElement, ReactNode, useMemo } from "react";
import { useForm } from "react-hook-form";
import { t } from "@lingui/macro";

import { UserAvatar } from "_/components/avatar";
import { Button, ButtonGroup } from "_/components/button";
import { DateTime, dateOnlyString } from "_/components/date-time";
import { Machine } from "_/data/machines";
import { AdminMachineListing } from "_/components/machine-listing";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { Redirect, Route, Switch, useRoute } from "_/components/router";
import { ColumnDef, Table } from "_/components/table";
import { TabBar, TabForRoute } from "_/components/tab-bar";
import { FormTextField } from "_/components/text-field";
import { InviteUserForm, UserDetailForm } from "_/components/settings-view";
import { UuidTooltip } from "_/components/uuid-tooltip";
import { FormField } from "_/components/form-field";
import { Roles } from "_/components/rbac";
import { PermissionTest, testPermissions } from "_/components/permission-test";

import { useMaterials } from "_/data/materials";
import {
  Org,
  useCheckCurrentOrg,
  useOrg,
  useOrgs,
  useCurrentOrg,
} from "_/data/orgs";
import {
  OrganizationUser,
  User,
  useUsersByOrg,
  useReviewUserOrgInvite,
  useIsAonUser,
} from "_/data/users";
import {
  permissions,
  ROOT_DOMAIN,
  useAssignableRoles,
  Permission,
  useCurrentUserPermissions,
} from "_/data/rbac";

import { getLanguageName } from "_/i18n";
import { adminRoutes, adminUrls, settingsUrls } from "_/routes";
import { Uuid } from "_/types";

import { FactoryMachines } from "./factory";
import { Materials } from "./materials";
import { Organizations } from "./organizations";

import * as S from "./styled";

type TabInfo = {
  name: string;
  route: string;
  url: string;
  title?: Record<string, ReactNode>;
  Component: () => ReactElement;
  requiredPermissions?: Permission | Permission[] | Permission[][];
};

const OrgName = () => {
  const [_, params] = useRoute(adminRoutes.organization);
  const { data: org } = useOrg(params?.id);
  const name = org?.name;
  return t`components.admin-view.admin-title ${name}`;
};

const TopLevelTabs = ({
  tabs,
  showTabBar,
}: {
  tabs: TabInfo[];
  showTabBar: boolean;
}): ReactElement => {
  return (
    <S.Header>
      {showTabBar && (
        <S.Nav>
          <TabBar>
            {tabs.map(({ name, route, url }) => (
              <TabForRoute key={route} route={route} url={url}>
                {name}
              </TabForRoute>
            ))}
          </TabBar>
        </S.Nav>
      )}
      {tabs.map(({ name, route, title }) => (
        <Route path={route} key={route}>
          <h1>
            {title
              ? Object.keys(title).map((key) => (
                  <Route path={key} key={key}>
                    {title[key]}
                  </Route>
                ))
              : name}
          </h1>
        </Route>
      ))}
    </S.Header>
  );
};

interface UsersListProps {
  org: Org;
}

const UsersList = ({ org }: UsersListProps) => {
  const { data: members } = useUsersByOrg(org.id);
  const { data: orgs } = useOrgs();
  const { closeModal, openModal, modalData, Modal } = useModal<User>();
  const reviewUserOrgInvite = useReviewUserOrgInvite();

  const { data: assignableRoles } = useAssignableRoles(org.domainId);

  const { data: permissions } = useCurrentUserPermissions(org.domainId);

  const canEdit = testPermissions("users/write", permissions);

  const canReviewInvite = testPermissions(
    "organizations/reviewMember",
    permissions
  );

  function submitReview(userId: Uuid, orgId: Uuid, approved: boolean) {
    reviewUserOrgInvite.mutateAsync({
      userId,
      orgId,
      approved,
    });
  }

  // Filter out users whose invite has been denied.
  const filteredMembers = members?.filter(
    (member) => !member.reviewedBy || member.approved
  );

  if (!filteredMembers?.length || !orgs?.length) {
    return null;
  }

  const pendingString = t`common.pending`;

  const columns: ColumnDef<OrganizationUser>[] = [
    {
      id: "avatar_id",
      header: "",
      cell: ({ row: { original: rowData } }) => {
        const user = rowData.user;
        if (!user) return null;
        return (
          <div title={user.name}>
            <UserAvatar resource={user} size="small" />
          </div>
        );
      },
    },
    {
      id: "name",
      header: t`common.name`,
      accessorKey: "user.name",
      cell: ({ row: { original: rowData } }) =>
        rowData.user.name + (rowData.approved ? "" : ` (${pendingString})`),
    },
    {
      id: "email",
      header: t`common.email`,
      accessorKey: "user.email",
      cell: ({ row: { original: rowData } }) => rowData.user.email,
    },
    {
      id: "phone",
      header: t`common.phone`,
      accessorKey: "user.phone",
      cell: ({ row: { original: rowData } }) => rowData.user.phone,
    },
    {
      id: "language",
      header: t`common.language`,
      accessorKey: "user.language",
      cell: ({ row: { original: rowData } }) => {
        return getLanguageName(rowData.user.language);
      },
    },
    {
      id: "verified",
      header: t`common.verified`,
      accessorKey: "user.verified",
      cell: ({ cell }) => cell.getValue() && <S.Checkmark>✓</S.Checkmark>,
    },
    {
      id: "roles",
      header: t`common.roles`,
      cell: ({ row: { original: rowData } }) => (
        <Roles user={rowData.user} org={org} showGlobalRoles={true} />
      ),
    },
    {
      id: "last_authenticated",
      header: t`common.last-authenticated`,
      accessorKey: "user.lastAuthenticated",
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.user.lastAuthenticated} />
      ),
    },
    {
      id: "id",
      header: t`common.id`,
      accessorKey: "user.id",
      cell: ({ row: { original: rowData } }) => (
        <UuidTooltip uuid={rowData.user.id} />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <ButtonGroup>
          {!rowData.reviewedBy && canReviewInvite && (
            <>
              <Button
                size="small"
                kind="primary"
                onClick={() => submitReview(rowData.user.id, org.id, true)}
              >
                {t`common.approve`}
              </Button>
              <Button
                size="small"
                kind="danger"
                onClick={() => submitReview(rowData.user.id, org.id, false)}
              >
                {t`common.deny`}
              </Button>
            </>
          )}
          <Button
            size="small"
            kind="secondary"
            onClick={() => openModal(rowData.user)}
            disabled={!canEdit}
          >
            {t`common.edit`}
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={filteredMembers}
        initialState={{ sorting: [{ id: "name", desc: false }] }}
      />
      <Modal title={t`components.admin-view.edit-user`}>
        {modalData && assignableRoles && (
          <UserDetailForm
            user={modalData}
            adminData={{
              org,
              assignableRoles,
            }}
            onClose={closeModal}
          />
        )}
      </Modal>
    </>
  );
};

const MachineRenameForm = ({
  machine,
  onClose,
}: {
  machine: Machine;
  onClose: () => void;
}) => {
  const defaultValues = {
    name: machine.name,
  };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    console.log(`rename machine ${machine.id}`, data);
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormField label={t`common.serial-number`}>
        {machine.hardware.serial}
      </FormField>
      <FormField label={t`common.machine-manufacture-date`}>
        {dateOnlyString({ value: machine.hardware.manufactureDate })}
      </FormField>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        placeholder={t`common.name`}
        rules={{ required: t`components.admin-view.name-required` }}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

// Component to display when the user does not have permission to view the org.
function InsufficientOrgPerms() {
  return (
    <S.OrgDeniedContainer>
      <S.OrgDeniedMessage>
        {t`components.admin-view.insufficient-org-perms`}
      </S.OrgDeniedMessage>
    </S.OrgDeniedContainer>
  );
}

const OrgOverview = () => {
  const [_, orgOverviewParams] = useRoute(adminRoutes.organization);
  const orgId = orgOverviewParams?.id;
  const renameModal = useModal<Machine>();
  const inviteModal = useModal<Org>();
  const { data: org } = useOrg(orgId);
  const { data: orgs } = useOrgs();
  const { data: currentOrg } = useCurrentOrg();

  // TODO: scope all of these to the org.
  const { data: _materials } = useMaterials();

  // Filter out organizations that the user does not have permission to view
  const readOrgs = orgs.filter((org) =>
    org.permissions.includes(permissions.organizations.read)
  );

  // Check if the user has permission to view the org based on whether the org
  // is in the list of orgs the user can read.
  const canReadOrg = readOrgs.some((o) => o.id === orgId);

  // Check if the user is trying to view the current org.
  const viewingCurrentOrg = currentOrg?.id === orgId;

  if (!org || !canReadOrg) {
    // If the user does not have permission to view the the current org, display
    // an error message rather than redirecting to the main admin page; redirecting
    // in this case could cause an infinite loop. This case should never happen, as
    // the user should have read permissions to an org they are a member of.
    return viewingCurrentOrg ? (
      <InsufficientOrgPerms />
    ) : (
      <Redirect to={adminUrls.index} />
    );
  }

  const actions = (machine: Machine) => [
    {
      render: t`common.support`,
      onClick: () => {
        // Construct mailto for getting machine specific support.
        const uri = [
          `mailto:help@aon3d.com`,
          `?subject=Basis Machine Support [${machine.hardware.serial}]`,
        ].join("");
        window.open(encodeURI(uri));
      },
    },
    {
      render: t`common.rename`,
      // TODO: I assume we can change serial number, production date,
      // and other info before shipping the machine.
      onClick: () => renameModal.openModal(machine),
    },
  ];

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`common.machines`}</h2>
        </S.AdminContentSectionTitleContainer>
        <AdminMachineListing orgId={orgId} actions={actions} />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`common.users`}</h2>
          <PermissionTest
            domainId={org.domainId}
            requiredPermissions={"users/invite"}
            render={({ allowed }) => (
              <Button
                disabled={!allowed}
                onClick={() => inviteModal.openModal(org)}
              >
                {t`common.invite-user`}
                {inviteModal.modalData && (
                  <inviteModal.Modal
                    title={t`common.invite-user-to-org ${org.name}`}
                  >
                    <InviteUserForm
                      org={org}
                      onClose={inviteModal.closeModal}
                    />
                  </inviteModal.Modal>
                )}
              </Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <UsersList org={org} />
      </S.AdminContentSection>
      <renameModal.Modal title={t`components.admin-view.rename-machine`}>
        {renameModal.modalData && (
          <MachineRenameForm
            onClose={renameModal.closeModal}
            machine={renameModal.modalData}
          />
        )}
      </renameModal.Modal>
    </S.AdminContent>
  );
};

export const AdminView = () => {
  const isAonUser = useIsAonUser();
  const { data: rootPermissions } = useCurrentUserPermissions(ROOT_DOMAIN);
  const { data: currentOrg } = useCheckCurrentOrg();

  const { routes, tabs, showTabBar } = useMemo(() => {
    const tabs: TabInfo[] = [
      {
        name: t`components.admin-view.organizations`,
        route: adminRoutes.wildcards.organizations,
        url: adminUrls.organizations,
        title: {
          [adminRoutes.organization]: <OrgName />,
          [adminRoutes.organizations]: t`components.admin-view.organizations`,
        },
        Component: () => <Organizations />,
        requiredPermissions: [["organizations/read"], ["machines/read"]],
      },
      {
        name: t`components.admin-view.machine-production`,
        route: adminRoutes.machines,
        url: adminUrls.machines,
        Component: () => <FactoryMachines />,
        requiredPermissions: "machines/read",
      },
      {
        name: t`common.materials`,
        route: adminRoutes.materials,
        url: adminUrls.materials,
        Component: () => <Materials />,
        requiredPermissions: "materials/read",
      },
    ];

    // Filter out tabs that the user doesn't have permission to see
    const filteredTabs = tabs.filter((tab) =>
      testPermissions(tab.requiredPermissions ?? [], rootPermissions)
    );

    // If there are no tabs the user can see, don't show the tab bar
    const showTabBar = isAonUser && !!filteredTabs.length;

    // Redirect URL for managing the current org. If no current org is set,
    // redirect to the Organizations tab of the settings page.
    const currentOrgRedirectUrl = currentOrg
      ? adminUrls.organization(currentOrg.id)
      : settingsUrls.organizations;

    // Redirect URL for internal users. If the tab bar is displayed for this user,
    // redirect to the first accessible tab. Otherwise, use the redirect for the
    // current org defined above.
    const redirectUrl = showTabBar
      ? filteredTabs[0].url
      : currentOrgRedirectUrl;

    const tabRoutes = filteredTabs.map((tab) => (
      <Route key={tab.route} path={tab.route} component={tab.Component} />
    ));

    return {
      routes: !isAonUser ? (
        // Non-internal users can only see the manage page for their own org
        <Switch>
          <Route path={adminRoutes.organization} component={OrgOverview} />
          <Redirect to={currentOrgRedirectUrl} replace />
        </Switch>
      ) : (
        <Switch>
          <Route path={adminRoutes.organization} component={OrgOverview} />
          {tabRoutes}
          <Redirect to={redirectUrl} replace />
        </Switch>
      ),
      tabs: filteredTabs,
      showTabBar,
    };
  }, [currentOrg, rootPermissions, isAonUser]);

  return (
    <S.Wrapper>
      <TopLevelTabs tabs={tabs} showTabBar={showTabBar} />
      <S.MainContent>{routes}</S.MainContent>
    </S.Wrapper>
  );
};
